'use client'; // Error components must be Client Components

import { useEffect } from 'react';
import logger from '@/lib/client/utils/logger';

export default function ErrorBoundary({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    const renderingError = new Error(error.message);
    renderingError.name = `ReactRenderingError`;
    renderingError.stack = error.stack;
    renderingError.cause = error;

    logger.error(renderingError, renderingError.name, { digest: error.digest });
  }, [error]);

  return (
    <div className='flex flex-col gap-4 items-center justify-center h-screen'>
      <h2 className='text-lg font-medium'>Something went wrong!</h2>
      <button
        className='inline-flex items-center justify-center px-4 h-10 rounded-full bg-white text-ruby transition-colors hover:bg-zinc-100'
        onClick={
          // Attempt to recover by trying to re-render the segment
          () => reset()
        }
      >
        Try again
      </button>
    </div>
  );
}
